import React from 'react'
import { useApp } from './../hooks/use-app-context'

import Layout from '../components/layout'
import SEO from '../components/seo'

function IndexPage() {
  const [app, context, send] = useApp()

  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />
      Auth value: {app.auth?.user?.firstName}
      <br />
      Context value: {app.test}
      <br />
      Update context:
      <button onClick={() => send('LOGIN')}>LOGIN</button>
      <button onClick={() => send('RETRY')}>RETRY</button>
      <br />
      Auth state: {context.value}
    </Layout>
  )
}

export default IndexPage
